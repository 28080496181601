// import { twosTourney } from './tournament-layouts/twos-tourney'
import { normalTourney } from './tournament-layouts/normal-tourney'

export default function Tournament() {

    return normalTourney();
}



